<template>
  <page-header :title="pageTitle" />

  <template v-if="bookmarks !== null">
    <template v-if="bookmarks.length > 0">
      <q-card>
        <article v-for="(bookmark, index) in bookmarks" :key="bookmark.id" class="bookmark">
          <div class="bookmark__index">
            {{ index + 1 }}.
          </div>
          <div class="bookmark__content">
            <div class="bookmark__name">
              <router-link :to="{name: 'terms_view', params: {id: bookmark.term.id}}">{{ bookmark.term.name }}</router-link>
            </div>
            <div v-if="bookmark.term.teaser" class="bookmark__teaser">
              {{ bookmark.term.teaser }}
            </div>
          </div>
          <div class="bookmark__actions">
            <q-btn class="bookmark__remove" @click="showDeleteBookmarkDialog(bookmark)">
              <q-icon>
                <img src="../../assets/icons/button/destroy.svg" alt="">
              </q-icon>
              Удалить
            </q-btn>
          </div>
        </article>
      </q-card>

      <pagination :page="filter.page" :pages="pages" />
    </template>
    <div v-else class="no-results-wrapper">
      <q-card class="no-results">
        <div>
          <img src="../../assets/icons/no-results.svg" class="no-results__icon" alt="">
          <div class="no-results__title">У вас пока нет списка слов</div>
          <div class="no-results__content">
            Здесь будут отображаться слова, которые
            вы добавили в Мои закладки.
          </div>
        </div>
      </q-card>
    </div>
  </template>
</template>

<script>
import loading from '../../loading.js'
import { api as bookmarksApi } from '../../api/bookmarks.js'
import PageHeader from '../../blocks/PageHeader.vue'
import DeleteBookmarkDialog from '../../dialogs/bookmarks/DeleteBookmarkDialog.vue'
import Pagination from '../../blocks/Pagination.vue'

const createFilter = function(query) {
  const filter = {
    page: 1,
  }

  if (query['page']) {
    filter.page = parseInt(query['page'])
  }

  return filter
}

async function preparePage(callback, to) {
  loading.start()
  const filter = createFilter(to.query)
  const bookmarks = await bookmarksApi.findBy(filter)
  const setInitData = vm => {
    vm.bookmarks = bookmarks
    vm.pages = bookmarks.meta.pages
    vm.total = bookmarks.meta.total
    vm.setFilter(filter)
  }
  callback(setInitData)
  loading.stop()
  loading.updateTitle(to.meta.title || '')
}

export default {
  components: {
    PageHeader,
    Pagination,
  },
  async beforeRouteEnter (to, from, next) {
    await preparePage(setInitData => {
      next(setInitData)
    }, to)
  },
  async beforeRouteUpdate(to, from, next) {
    await preparePage(setInitData => {
      setInitData(this)
      next()
    }, to)
  },
  data() {
    return {
      bookmarks: null,
      filter: { page: 1 },
      pages: 1,
      total: 0,
    }
  },
  methods: {
    async reload() {
      const bookmarks = await bookmarksApi.findBy(this.filter)
      const redirect = bookmarks.checkEmptyPage()
      if (redirect) {
        this.$router.push(redirect)
      } else {
        this.bookmarks = bookmarks
        this.pages = bookmarks.meta.pages
        this.total = bookmarks.meta.total
      }
    },
    setFilter(filter) {
      this.filter = filter
    },
    showDeleteBookmarkDialog(bookmark) {
      this.$root
        .createDialog({
          component: DeleteBookmarkDialog,
          componentProps: {
            bookmark: bookmark,
            hook: this.reload,
          },
        })
        .onOk(bookmark => {
          this.$root.showSuccessMessage(`Слово «${bookmark.term.name}» удалено из закладок.`)
        })
    },
  },
  computed: {
    pageTitle() {
      return 'Мои закладки' + (this.total ? ` (${this.total})` : '')
    }
  },
}
</script>

<style lang="scss">
.page-bookmarks-list {
  display: flex;
  flex-direction: column;
}

.no-results-wrapper {
  display: flex;
  flex: 1;
}

.no-results {
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding: 60px 60px 60px 122px;

  &__icon {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 73px;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  &__content {
    margin-top: 27px;
    color: rgba(0, 0, 0, 0.53);
  }
}
</style>
